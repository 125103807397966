import React, { CSSProperties, useEffect, useState } from 'react';

import HomeScreenBanner from '@/components/cards/HomeScreenBanner';
import { HomeScreenReleaseCycles } from '@/components/release-cycle/HomeScreenReleaseCycles';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import CustomIcons from '@/components/utility/microcomponents/CustomIcons';
import { WidgetsComponent } from '@/components/widgets/WidgetsComponent';
import { useWalkthrough } from '@/hooks/context/useWalkthrough';
import useUserTracking from '@/hooks/useUserTracking';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { VIEW_STATE } from '@/models/Enums';
import useFeatureFlags from '@/services/FeatureFlag';

import UserHeader from '../components/utility/navigation/UserHeader';

const HomePage = () => {
  const userTracking = useUserTracking();
  const { isHomeScreenBannerEnabled } = useFeatureFlags();
  const { breakpointHit } = useBreakpoints();
  const { viewState, setViewState } = useWalkthrough();
  const [showWalkthrough, setShowWalkthrough] = useState<boolean>(false);

  useEffect(() => {
    if (userTracking) userTracking.userHomeScreenViewed();
  }, [userTracking]);

  useEffect(() => {
    setTimeout(() => {
      setShowWalkthrough(true);
    }, 2000);
  }, []);

  return (
    <div id="home-page" data-testid="home-page" className="page-content">
      <UserHeader title={'PAGE-TITLES.HOME'} isHome />
      {isHomeScreenBannerEnabled && (
        <div className="mt20 w100p">
          <HomeScreenBanner />
        </div>
      )}

      <div className="mt20">
        <HomeScreenReleaseCycles />
      </div>
      <WidgetsComponent />
      {breakpointHit && showWalkthrough && (
        <div
          className={`walkthrough-resume-button-border fade-in w100p mt20 mb8 ${viewState && viewState === VIEW_STATE.SKIPPED ? '' : ''}`}
        >
          <div className="walkthrough-resume-button p8">
            <ButtonComponent isCustom className={`w100p jc-center`} onClick={() => setViewState(VIEW_STATE.UNSEEN)}>
              <div className="w100p">
                <div className="d-flex jc-space-between w100p">
                  <p
                    className={`nav-footer-text logout-text pt4 fs-custom`}
                    style={{ '--customFontSize': '14px' } as CSSProperties}
                  >
                    Take a look around
                  </p>
                  <CustomIcons className="logout-icon text-white walkthrough-icon" name="walkthrough" />
                </div>

                <p className="text-faded text-left small">
                  Get started with our walkthrough to learn about the ins-and-outs of using the app.
                </p>
              </div>
            </ButtonComponent>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
